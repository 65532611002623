<template>
  <div :class="['area-picker',areaSize ? 'area-picker--' + areaSize : '', {'is-disabled': areaDisabled}]"
    v-clickoutside="handleClose">
    <div tabindex="1" class="area-picker-selection" @click="toggleOpen" @focus="handleFocus" @blur="handleBlur"
      ref="pickSelection">
      <span style="color: #dddee1" v-if="!currentValue ">{{ placeholder }}</span>
      <template v-if="multi">
        <el-tag v-for="item in currentValue" :key="item.name" :closable="!areaDisabled" @close="handleRemove(item)"
          type="info" disable-transitions>
          <span v-if="level ==1">{{item.province.name}}</span>
          <span v-if="level ==2">{{item.province.name}} / {{item.city?item.city.name:'' }}</span>
          <span v-if="level ==3">
            {{item.province.name}} / {{item.city?item.city.name:'' }} /
            {{item.district?item.district.name:''}}
          </span>
        </el-tag>
      </template>
      <template v-else>
        <el-tag :closable="!areaDisabled" @close="handleRemove(currentValue)" type="info" disable-transitions
          v-if="!isEmptyObject(currentValue)">
          <span v-if="level ==1">{{currentValue.province.name}}</span>
          <span v-if="level ==2">{{currentValue.province.name}} /
            {{currentValue.city?currentValue.city.name:'' }}</span>
          <span v-if="level ==3">
            {{currentValue.province.name}} / {{currentValue.city?currentValue.city.name:'' }} /
            {{currentValue.district?currentValue.district.name:''}}
          </span>
        </el-tag>
      </template>
    </div>
    <div class="area-picker-arrow">
      <i class="el-icon-arrow-down" @click="toggleOpen" :class="{'is-reverse': opened}"></i>
    </div>
    <div class="area-picker-dropdown" v-show="opened" :style="dropDownStyle">
      <ul class="picker-nav">

        <el-autocomplete v-if="level>1" popper-class="area-search-list" class="area-search-input" v-model="query"
          ref="queryInput" :fetch-suggestions="querySearch" placeholder="请输入关键词查询" @select="handleQueryResultSelect">
        </el-autocomplete>

        <li v-if="level>0" :class="{'active':viewName=='Province'}">
          <a @click="showArea('Province')">省份</a>
        </li>
        <li v-if="level>1" :class="{'active':viewName=='City'}">
          <a @click="showArea('City')">城市</a>
        </li>
        <li v-if="level>2" :class="{'active':viewName=='District'}">
          <a @click="showArea('District')">区县</a>
        </li>
      </ul>
      <div class="pick-data-wrap">
        <div class="pick-data-province" v-show="viewName=='Province'">
          <a v-for="(item,index) in data" :key="index" @click="renderCity(index)">
            <template v-if="multi">
              <template v-for="(v,index) in currentValue">
                <template v-if="v.province && item[keyName] == v.province[keyName]">
                  <i class="el-icon-circle-check" :key="index"></i>
                </template>
              </template>
            </template>
            <template v-else>
              <template v-if="currentValue && currentValue.province && item[keyName] == currentValue.province[keyName]">
                <i class="el-icon-circle-check" :key="index"></i>
              </template>
            </template>
            {{item.label}}
          </a>
        </div>
        <div class="pick-data-province" v-show="viewName=='City'">
          <a v-for="(item,index) in cityData" :key="index" @click="renderDistrict(index,item)">
            <template v-if="multi">
              <template v-for="(v,index) in currentValue">
                <template v-if="v.city && item[keyName] == v.city[keyName]">
                  <i class="el-icon-circle-check" :key="index"></i>
                </template>
              </template>
            </template>
            <template v-else>
              <template v-if="currentValue && currentValue.city && item[keyName] == currentValue.city[keyName]">
                <i class="el-icon-circle-check" :key="index"></i>
              </template>
            </template>
            {{item.label}}
          </a>
        </div>
        <div class="pick-data-province" v-show="viewName=='District'">
          <a v-for="(item,index) in districtData" :key="index" @click="pickDistrict(item)">
            <template v-if="multi">
              <template v-for="(v,index) in currentValue">
                <template v-if="v.district && item[keyName] == v.district[keyName]">
                  <i class="el-icon-circle-check" :key="index"></i>
                </template>
              </template>
            </template>
            <template v-else>
              <template v-if="currentValue && currentValue.district && item[keyName] == currentValue.district[keyName]">
                <i class="el-icon-circle-check" :key="index"></i>
              </template>
            </template>
            {{item.label}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import emitter from "element-ui/src/mixins/emitter";
  import {
    mapActions
  } from "vuex";
  import {
    arrayRemove,
    isEmptyObject
  } from "../../utils/utils";

  import clickoutside from "element-ui/src/utils/clickoutside";
  export default {
    name: "GsArea",
    mixins: [emitter],
    directives: {
      clickoutside,
    },
    inject: {
      elForm: {
        default: "",
      },
      elFormItem: {
        default: "",
      },
    },
    props: {
      value: {
        type: [Array, Object],
        default () {
          return [];
        },
      },
      keyName: {
        //使用id  还是 value 作为key，影响 默认初始化
        type: String,
        default: "id",
      },
      size: String,
      level: {
        type: Number,
        default: 3, // 1,2,3
      },
      multi: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      placement: {
        //出现位置
        type: String,
        default: "left",
      },
      beforeOpen: {
        type: Function,
      },
      fixedPosition: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: "请选择城市"
      }
    },
    computed: {
      dropDownStyle() {
        let style = {};
        style.top = this.selHeight + "px";
        if (!this.fixedPosition) {

          if (this.placement == "right") style.right = 0;
        } else {

          style.position = 'fixed';
          style.width = '350px';
        }

        return style;
      },
      _elFormItemSize() {
        //继承表单的size
        return (this.elFormItem || {}).elFormItemSize;
      },
      areaSize() {
        return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
      },
      areaDisabled() {
        return this.disabled || (this.elForm || {}).disabled;
      },
    },
    data() {
      return {
        opened: false, //
        focused: false,
        data: [],
        currentValue: this.value === undefined || this.value === null ?
          this.multi ? [] :
          this.value : this.value,
        selectIdnex: null,
        cityIndex: null,
        viewName: "Province",
        cityData: [],
        districtData: [],
        selHeight: 0,
        query: ''
      };
    },
    methods: {
      ...mapActions(["cache/getWithCache"]),
      handleClose() {
        if (this.opened) {
          this.opened = false;
          this.$emit("on-picker-close");
        }
      },
      handleFocus(event) {
        this.focused = true;
        this.$emit("focus", event);
      },
      handleBlur(event) {
        if (!this.opened) {
          this.focused = false;
          this.$emit("blur", event);
          this.dispatch("ElFormItem", "el.form.blur", this.currentValue);
        }
      },
      toggleOpen() {
        if (this.areaDisabled) {
          return false;
        }
        if (this.beforeOpen && !this.beforeOpen()) {
          return false;
        }
        //计算selection区域的高度以便显示 下拉区域的位置
        this.updateSelectHeight();
        this.opened = !this.opened;
        
        //只显示2级城市时，可能页面显示会有点问题
        if(this.currentValue) {
          if(this.currentValue.district) {
            this.viewName = "District"
          } else if (this.currentValue.city) {
            this.viewName = "City"
          } else if (this.currentValue.province) {
            this.viewName = "Province"
          }
        } else {
          this.viewName = "Province"
        }
        // if (this.opened) {
        //   this.$nextTick(() => {
        //     this.$refs.queryInput.focus();
        //   })
        // }
      },
      updateSelectHeight() {
        if(!this.fixedPosition){
          this.selHeight = this.$refs.pickSelection.offsetHeight;
        }else{
          this.selHeight = this.$refs.pickSelection.offsetHeight + this.$refs.pickSelection.getBoundingClientRect().top
        }

      },
      showArea(name) {
        if (name == 'City') {
          if (this.currentValue && this.currentValue.province) {
            this.viewName = name;
          }
        } else if (name == 'District') {
          if (this.currentValue && this.currentValue.city) {
            this.viewName = name;
          }
        } else {
          this.viewName = name;
        }
      },
      renderProvince(index) {
        this.selectIdnex = index;
        const sp = this.data[this.selectIdnex];
        const isExist = this.checkIsExist(sp);
        if (this.level == 1 && isExist) {
          return;
        } else {
          this.setValue(sp);
        }
        this.handleClose();
      },
      renderCity(index) {
        this.selectIdnex = index;
        const sp = this.data[this.selectIdnex];
        const isExist = this.checkIsExist(sp);
        this.cityData = this.data[this.selectIdnex]["children"];
        if (isExist) {
          return;
        } else {
          this.setValue(sp);
        }
        if (this.level == 1) {
          this.handleClose();
        } else {
          //this.cityData = this.data[this.selectIdnex]['children']
          this.viewName = "City";
        }
      },
      renderDistrict(index, item) {
        if (this.selectIdnex == null) {
          this.$message({
            message: "请先选择省份",
            type: "warning",
          });
          return;
        }
        this.cityIndex = index;
        const sp = this.data[this.selectIdnex];
        const ct = this.cityData[this.cityIndex];
        this.districtData = item.children;
        const isExist = this.checkIsExist(ct);
        if (isExist) {
          return;
        } else {
          this.setValue(sp, ct);
        }
        if (this.level == 2) {
          this.handleClose();
        } else {
          if (this.opened == true) {
            this.viewName = "District";
          }
        }
      },
      pickDistrict(item) {
        if (this.selectIdnex == null) {
          this.$message({
            message: "请先选择省份",
            type: "warning",
          });
          return;
        }
        if (this.cityIndex == null) {
          this.$message({
            message: "请先选择城市",
            type: "warning",
          });
          return;
        }
        const sp = this.data[this.selectIdnex];
        const ct = this.cityData[this.cityIndex];
        const isExist = this.checkIsExist(item);
        if (this.level == 3 && isExist) {
          return;
        } else {
          this.setValue(sp, ct, item);
        }
        this.handleClose();
        this.viewName = "Province";
      },
      setValue(sp, ct, dt) {
        if (this.multi) {
          let s = {};
          if (sp) {
            s.province = {
              id: sp.id,
              value: sp.value,
              name: sp.label,
            };
          }
          if (ct) {
            s.city = {
              id: ct.id,
              value: ct.value,
              name: ct.label,
            };
          }
          if (dt) {
            s.district = {
              id: dt.id,
              value: dt.value,
              name: dt.label,
            };
          }
          this.currentValue.push(s);
        } else {
          let s = {};
          if (sp) {
            s.province = {
              id: sp.id,
              value: sp.value,
              name: sp.label,
            };
          }
          if (ct) {
            s.city = {
              id: ct.id,
              value: ct.value,
              name: ct.label,
            };
          }
          if (dt) {
            s.district = {
              id: dt.id,
              value: dt.value,
              name: dt.label,
            };
          }
          this.currentValue = s;
        }
        this.$emit("input", this.currentValue); //
        this.$emit("change", this.currentValue);
        this.dispatch("ElFormItem", "el.form.change", this.currentValue); //触发所在表单的校验事件
      },
      checkIsExist(item) {
        if (this.value) {
          if (!this.multi) {
            if (
              this.value.province &&
              this.value.province[this.keyName] == item[this.keyName]
            ) {
              return true;
            }
            if (
              this.value.city &&
              this.value.city[this.keyName] == item[this.keyName]
            ) {
              return true;
            }
            if (
              this.value.district &&
              this.value.district[this.keyName] == item[this.keyName]
            ) {
              return true;
            }
          } else {
            for (const d of this.value) {
              if (d.province && d.province[this.keyName] == item[this.keyName]) {
                return true;
              }
              if (d.city && d.city[this.keyName] == item[this.keyName]) {
                return true;
              }
              if (d.district && d.district[this.keyName] == item[this.keyName]) {
                return true;
              }
            }
          }
        }

        return false;
      },
      handleRemove(item) {
        if (this.multi) {
          let inx = null;
          this.currentValue.find((v, index) => {
            if (
              v.province &&
              v.province[this.keyName] == item.province[this.keyName]
            ) {
              inx = index;
            }
            if (v.city && v.city[this.keyName] == item.city[this.keyName]) {
              inx = index;
            }
            if (
              v.district &&
              v.district[this.keyName] == item.district[this.keyName]
            ) {
              inx = index;
            }
          });
          if (inx != null) {
            arrayRemove(this.currentValue, inx, inx);
            this.selectIdnex = null;
            this.cityIndex = null;
            this.viewName = "Province";
          }
        } else {
          this.currentValue = null;
        }
        this.$emit("input", this.currentValue); //
        this.$emit("change", this.currentValue);
        this.dispatch("ElFormItem", "el.form.change", this.currentValue); //触发所在表单的校验事件
      },
      isEmptyObject(obj) {
        return isEmptyObject(obj);
      },
      getData() {
        const cacheParam = {
          ckey: "shengsq",
          url: "api/RegionService/getRegionSs",
          param: {},
          cacheType: "localstorage",
        };
        return this["cache/getWithCache"](cacheParam);
      },
      setDefault() {
        if (this.multi) {
          if (this.currentValue.length > 0) {
            for (let i = 0; i < this.currentValue.length; i++) {
              this.data.find((v, index) => {
                if (
                  v[this.keyName] == this.currentValue[i].province[this.keyName]
                ) {
                  if (this.level == 1) {
                    this.currentValue[i].province.name = v.label;
                    this.renderProvince(index);
                  } else if (this.level == 2) {
                    this.currentValue[i].province.name = v.label;
                    const cdata = v.children;
                    cdata.find((c, index) => {
                      if (
                        this.currentValue[i].city &&
                        c[this.keyName] == this.currentValue[i].city[this.keyName]
                      ) {
                        this.currentValue[i].city.name = c.label;
                      }
                    });
                    this.renderCity(index);
                  } else {
                    this.currentValue[i].province.name = v.label;
                    this.selectIdnex = index;
                    const cdata = v.children;
                    this.cityData = cdata;
                    if (this.currentValue[i].city) {
                      this.cityData.find((c, index) => {
                        if (
                          this.currentValue[i].city &&
                          c[this.keyName] ==
                          this.currentValue[i].city[this.keyName]
                        ) {
                          this.currentValue[i].city.name = c.label;
                          this.renderDistrict(index, c);
                          const ddata = c.children;
                          if (this.currentValue[i].district) {
                            ddata.find((d, index) => {
                              if (
                                this.currentValue[i].district &&
                                d[this.keyName] ==
                                this.currentValue[i].district[this.keyName]
                              ) {
                                this.currentValue[i].district.name = d.label;
                              }
                            });
                          }
                        }
                      });
                    }
                  }
                }
              });
            }
          }
        } else {
          this.data.find((v, index) => {
            if (this.currentValue && this.currentValue.province) {
              if (v[this.keyName] == this.currentValue.province[this.keyName]) {
                if (this.level == 1) {
                  this.currentValue.province.name = v.label;
                  this.renderProvince(index);
                } else if (this.level == 2) {
                  this.currentValue.province.name = v.label;
                  const cdata = v.children;
                  cdata.find((c, index) => {
                    console.log(this);
                    console.log(this.currentValue);
                    if (
                      this.currentValue.city &&
                      c[this.keyName] == this.currentValue.city[this.keyName]
                    ) {
                      this.currentValue.city.name = c.label;
                    }
                  });
                  this.renderCity(index);
                } else {
                  this.currentValue.province.name = v.label;
                  this.$set(
                    this.currentValue.province,
                    this.keyName,
                    v[this.keyName]
                  );
                  this.selectIdnex = index;
                  const cdata = v.children;
                  this.cityData = cdata;
                  if (this.currentValue.city) {
                    this.cityData.find((c, index) => {
                      if (
                        this.currentValue.city &&
                        c[this.keyName] == this.currentValue.city[this.keyName]
                      ) {
                        this.currentValue.city.name = c.label;
                        this.$set(
                          this.currentValue.city,
                          this.keyName,
                          c[this.keyName]
                        );
                        this.renderDistrict(index, c);
                        const ddata = c.children;
                        if (this.currentValue.district) {
                          ddata.find((d, index) => {
                            if (
                              this.currentValue.district &&
                              d[this.keyName] ==
                              this.currentValue.district[this.keyName]
                            ) {
                              this.currentValue.district.name = d.label;
                              this.$set(
                                this.currentValue.district,
                                this.keyName,
                                d[this.keyName]
                              );
                            }
                          });
                        }
                      }
                    });
                  }
                }
              }
            }
          });
        }
      },
      querySearch(queryString, cb) {
        let selections = [];
        let selectionsCity = [];

        function getSelections(arr, label, value, id) {
          for (let i = 0; i < arr.length; i++) {
            let item = arr[i];
            item.__label = label ? label + ' / ' + item.label : item.label;
            item.__value = value ? value + ',' + item.value : item.value;
            item.__id = id ? id + ',' + item.id : item.id;
            if (item.children && item.children.length) {
              if (item.__value.indexOf(',') > -1) {
                selections.push({
                  label: item.__label,
                  value: item.__value,
                  display: item.__label,
                  id: item.__id,
                  item: item,
                  disabled: !!item.disabled
                })
              }

              getSelections(item.children, item.__label, item.__value, item.__id);
              delete item.__label;
              delete item.__value;
            } else {
              selections.push({
                label: item.__label,
                value: item.__value,
                display: item.__label,
                id: item.__id,
                item: item,
                disabled: !!item.disabled
              });
            }
          }
        }

        function getSelections2(arr) {
          for (let i = 0; i < arr.length; i++) {
            let sheng = arr[i];
            //let sheng={}
            sheng.__label = sheng.label;
            sheng.__value = sheng.value;
            sheng.__id = sheng.id;
            if (sheng.children && sheng.children.length > 0) {
              for (const child of sheng.children) {
                let city = child
                city.__label = sheng.__label + ' / ' + child.label;
                city.__value = sheng.__value + ',' + child.value;
                city.__id = sheng.__id + ',' + child.id;
                selectionsCity.push({
                  label: city.__label,
                  value: city.__value,
                  display: city.__label,
                  id: city.__id,
                  item: city,
                  disabled: !!city.disabled
                })
              }
            }
          }
        }
        //只有省市
        if (this.level == 3) {
          getSelections(this.data);
        } else {
          getSelections2(this.data);
        }

        //getSelections2(this.data);
        var querys = queryString.replace(/ /g, '/').split("/"); //以 / 分组(空格先替换成/)，安徽/芜湖 =》 ['安徽','芜湖']
        for (var i = 0; i < querys.length; i++) {
          if (querys[i] == "") { //去除空字符
            querys.splice(i, 1);
          }
        }
        if (querys.length > 1) { //多个搜索词，按顺序过滤，比如  ['安徽','芜湖'] 先过滤 安徽，再在结果里 过滤芜湖

          let tps = []; //临时结果，用于存放前一个搜索结果
          let tps2 = [];
          for (var i = 0; i < querys.length - 1; i++) {
            tps = selections.filter(item => item.label.indexOf(querys[i]) > -1).map(item => {

              return item;
            });
            tps2 = selectionsCity.filter(item => item.label.indexOf(querys[i]) > -1).map(item => {

              return item;
            });
          }
          //console.log(tps2)
          selections = tps.filter(item => item.label.indexOf(querys[querys.length - 1]) > -1).map(item => {
            item.display = item.display.replace(new RegExp(querys[querys.length - 1], 'g'),
              `${querys[querys.length-1]}`);
            return item;
          });
          let tps3 = []
          tps3 = tps2.filter(item => item.label.indexOf(querys[querys.length - 1]) > -1).map(item => {
            item.display = item.display.replace(new RegExp(querys[querys.length - 1], 'g'),
              `${querys[querys.length-1]}`);
            return item;
          });

          for (const tp of tps3) {
            selections.unshift(tp)
          }

        } else { //一个搜索词
          selections = selections.filter(item => item.label.indexOf(querys[0]) > -1).map(item => {
            item.display = item.display.replace(new RegExp(querys[0], 'g'), `${querys[0]}`);
            return item;
          });
          let tps4 = []
          tps4 = selectionsCity.filter(item => item.label.indexOf(querys[0]) > -1).map(item => {
            item.display = item.display.replace(new RegExp(querys[0], 'g'), `${querys[0]}`);
            return item;
          });

          for (const tp of tps4) {
            selections.unshift(tp)
          }
        }
        //去重
        const r = new Map();
        let j = selections.filter((a) => !r.has(a.label) && r.set(a.label, 1)).map(item => {
          return {
            value: item.display,
            value2: item.value,
            id2: item.id,
            item: item.item
          }
        });
        if (j.length == 0) {
          j = [{
              value: '建议直接输入市或者区来查询',
            },
            {
              value: '比如选择安徽省芜湖市镜湖区'
            }, {
              value: '可以输入 镜湖,或者 芜湖'
            }, {
              value: '如果要输入**省**市**区来查询'
            }, {
              value: '省市区之间需要空格隔开'
            }, {
              value: '如 安徽 芜湖 镜湖'
            }
          ]
        }
        cb(j);
      },
      handleQueryResultSelect(item) {
        this.query = '';
        if (!item.id2) {
          return
        }
        const ids = item.id2.split(",");
        const values = item.value2.split(",");
        if (this.level == 3 && ids.length == 3) {
          const proId = ids[0];
          const ctyId = ids[1];
          const dtrId = ids[2]
          this.data.find((v, index) => {
            if (proId == v.id) {
              this.selectIdnex = index;

              const cdata = v.children;
              this.cityData = cdata;
              cdata.find((c, index) => {
                if (ctyId == c.id) {
                  this.cityIndex = index;

                  const ddata = c.children;

                  ddata.find((d, index) => {
                    if (dtrId == d.id) {
                      const sp = this.data[this.selectIdnex];
                      const ct = this.cityData[this.cityIndex];
                      this.setValue(sp, ct, d)
                    }
                  })

                }
              })

            }
          })
        }

        if ((this.level == 2 || this.level == 3) && ids.length == 2) {
          const proId = ids[0];
          const ctyId = ids[1];
          this.data.find((v, index) => {
            if (proId == v.id) {
              this.selectIdnex = index;

              const cdata = v.children;
              this.cityData = cdata;
              cdata.find((c, index) => {
                if (ctyId == c.id) {
                  this.cityIndex = index;

                  const sp = this.data[this.selectIdnex];
                  const ct = this.cityData[this.cityIndex];
                  this.setValue(sp, ct)

                }
              })

            }
          })
        }
        //this.value = selectValue


      }

    },

    created() {
      this.getData().then((rs) => {
        const data = rs.data;
        this.data = data;
        //有默认值
        this.setDefault();
      });
    },
    destroyed() {},
    watch: {
      value: function (val, oval) {
        this.dispatch("ElFormItem", "el.form.change", val); //触发所在表单的校验事件
        this.currentValue = val;
        this.setDefault();
        this.updateSelectHeight();
      },
    },
  };
</script>
<style>
  .area-search-input {
    padding: 5px 0;
  }

  .area-search-list {
    z-index: 4001 !important;
  }

  .area-search-list .el-autocomplete-suggestion__list li {
    padding: 0 5px;
    font-size: 12px;
  }
</style>
