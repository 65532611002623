<template>
    <div class="unset-min-width zq_sure_subscribe">
        <div class="body_view" v-loading="pageLoding">
            <el-form :model="subscribeDto" label-width="100px" ref="dataDictRef" :rules="rules">
                <el-row type="flex" justify="center" style="margin-bottom: 20px">
                    <el-col :span="14">
                        <el-steps :active="stepActive" finish-status="success" align-center>
                            <el-step title="提交订阅"></el-step>
                            <el-step title="账单支付"></el-step>
                            <el-step title="安装部署"></el-step>
                            <el-step title="订阅完成"></el-step>
                        </el-steps>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :offset="4" :span="16">
                        <box class="box_view_top" title="用户信息">
                            <template v-slot:body>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item
                                                label="用户类型"
                                                prop="tenantInfo.type"
                                                :rules="{
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                      }"
                                        >
                                            <enum-selector
                                                    :disabled="stepActive != 0"
                                                    v-model="subscribeDto.tenantInfo.type"
                                                    placeholder="请选择"
                                                    clazz="TenantInfoTypeE"
                                            ></enum-selector>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item
                                                label="用户名称"
                                                prop="tenantInfo.name"
                                                :rules="{
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                      }"
                                        >
                                            <el-input
                                                    :disabled="stepActive != 0"
                                                    v-model="subscribeDto.tenantInfo.name"
                                                    placeholder="个人/企业名称"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item
                                                label="系统域名"
                                                prop="tenantInfo.domain"
                                                :rules="{
                        required: false,
                        message: '不能为空',
                        trigger: 'change',
                      }"
                                        >
                                            <el-input
                                                    :disabled="stepActive != 0"
                                                    v-model="subscribeDto.tenantInfo.domain"
                                                    placeholder="可不填，由系统分配"
                                            >
                                                <template slot="prepend">Https://</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <span style="margin-left: 10px;text-algin: center;line-height: 30px;"> 域名在订阅成功后生效</span>
                                    </el-col>
                                </el-row>
                            </template>
                        </box>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :offset="4" :span="16">
                        <box class="box_view_bottom" title="订阅信息">
                            <template v-slot:body>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="产品信息">
                                            <span style="margin-right: 10px">{{subscribeDto.proSubInfo.spName}}</span>
                                            <el-tag size="small" effect="dark"
                                                    :color="checkTagColor(subscribeDto.proSubInfo.typeName)"
                                                    v-if="subscribeDto.proSubInfo.typeName != null"
                                            >{{ subscribeDto.proSubInfo.typeName }}
                                            </el-tag
                                            >
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12" v-if="showPrice">
                                        <el-form-item label="订阅价格">
                                              <span class="amt_color" style="margin-right: 10px">{{
                                                subscribeDto.proSubInfo.unitPrice
                                              }}</span
                                              ><span>{{ subscribeDto.proSubInfo.unitName }}</span>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="产品简介">
                                            <span class="singleLineTxt">{{ subscribeDto.proSubInfo.spRemark }}</span>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="订阅备注">
                                            <span>{{ subscribeDto.proSubInfo.subRemark }}</span>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row v-if="stepActive == 0">
                                    <el-col :span="24" v-if="!bdTag && !dxTag">
                                        <el-form-item label="当前有效期">
                                            <span style="margin-right: 10px">{{subscribeDto.proSubInfo.newStartTime}}</span>
                                            <span v-if="mountNum != null && mountNum > 0"
                                            >您已选择订阅时长 <span style="color: #ff5733;"> {{ mountNum }} </span> 个月,有效期将延长至： <span
                                                    style="color: #ff5733;">{{ toDate }}</span></span
                                            >
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row v-if="stepActive == 0">
                                    <el-col :span="12">
                                        <el-form-item label="订阅备注" prop="proSubInfo.subRemark">
                                            <el-input v-model="subscribeDto.proSubInfo.subRemark" style="width: 100%;"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row v-if="(!bdTag && !dxTag) && showMomth">
                                    <el-col :span="24">
                                        <el-form-item
                                                :label="renew?'续订时长':'订阅时长'"
                                                prop="proSubInfo.mountNum"
                                        >
                                            <template v-if="stepActive == 0">
                                                <el-radio-group v-model="mountNum">
                                                    <template v-for="item in mountArray">
                                                        <el-radio-button
                                                                :disabled="disMounthSel(item.value)"
                                                                :label="item.value"
                                                                :key="item.value"
                                                                @change="mountNumChanged"
                                                        >{{ item.name }}
                                                        </el-radio-button
                                                        >
                                                    </template>
                                                </el-radio-group>
                                                <!--
                                                                                                <span style="display: block;color: #ff5733;margin-top: 10px;"
                                                                                                      v-if="minMountNum != null && minMountNum > 0"
                                                                                                >该产品最少订阅{{ minMountNum }}个月</span> -->

                                            </template>
                                            <template v-else>
                                                <span
                                                >{{ subscribeDto.proSubInfo.subMonths }}个月，从{{
                                                    subscribeDto.proSubInfo.billStartTime
                                                  }}
                                                  至 {{ subscribeDto.proSubInfo.billEndTime }}</span
                                                >
                                            </template>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <!--            计费暂不支持
                                                <el-row v-if="stepActive == 0 ">
                                                  <el-col :span="24">
                                                    <el-form-item label="订阅数量">
                                                      <el-input-number

                                                        v-model="selNums"
                                                        :step="1"
                                                        step-strictly
                                                      ></el-input-number>
                                                    </el-form-item>
                                                  </el-col>
                                                </el-row> -->

                                <el-row v-if="stepActive == 0 && (bdTag || dxTag)">
                                    <el-col :span="24">
                                        <el-form-item label="数据包">
                                            <template v-if="bdTag">
                                                <el-radio-group v-if="!inputAmount" v-model="dataSelNum" @change="handleDxRadio">
                                                    <template>
<!--                                                        <el-radio-button :label="item.value" :key="item.value">{{-->
<!--                                                            item.name }}-->
<!--                                                        </el-radio-button>-->

                                                      <el-radio-button  v-for="item in bdDataList"
                                                                        :label="item.value"
                                                                        :key="item.value"
                                                      >{{ item.name }}
                                                      </el-radio-button>   
                                                      <el-button type="text" @click="inputAmount=true;errorMsg=''" style="margin-left:5px;">自定义金额</el-button>                                                   
                                                    </template>
                                                </el-radio-group>
                                                
                                                <template v-if="inputAmount">
                                                    <el-input-number style="margin-left: 10px;margin-top: 0px;width: 130px" placeholder="自定义金额" v-model="dataSelAmount" @keydown="handleBdChange" controls-position="right" @change="handleBdChange" ></el-input-number>
                                                    <el-button type="text" @click="inputAmount=false;errorMsg=''" style="margin-left:5px;">选择系统数据包</el-button>
                                                    <span  class="errorMsg">{{errorMsg}}</span>
                                                </template>
                                              
                                                <span v-if="dataTitle" style=" display: block;color: #ff5733;margin-top: 10px;">{{dataTitle}}</span>
                                                <span v-else-if="reshunting" style=" display: block;color: #ff5733;margin-top: 10px;">满赠活动：套餐用于调车单发布或司机信息获取，折扣单价为{{bdDataList[dataSelNum - 1].price}}元/次</span>
                                                <span v-else style=" display: block;color: #ff5733;margin-top: 10px;">满赠活动：套餐包用于车辆实时定位或历史轨迹查询，折扣单价为定位{{bdDataList[dataSelNum - 1].price}}元/天/车、轨迹{{bdDataList[dataSelNum - 1].linePrice}}元/天/车</span>
                                            </template>
                                          
                                            <template v-else>
                                                <el-radio-group v-if="!inputAmount" v-model="dataSelNum" @change="handleDxRadio">
                                                    <template>
                                                        <el-radio-button  v-for="item in dxDataList"
                                                                :label="item.value"
                                                                :key="item.value"
                                                        >{{ item.name }}
                                                        </el-radio-button>
                                                        <el-button type="text" @click="inputAmount = true;"style="margin-left:5px;">自定义金额</el-button>

                                                     <!-- <el-input-number style="margin-left: 20px; margin-top:0px; width: 130px" placeholder="自定义金额" v-model="dataSelAmount" @keydown="handleDxChange" controls-position="right" @change="handleDxChange" ></el-input-number> -->
                                                        <!--  <el-input-number v-model="dataSelAmount" @blur="handleChange" controls-position="right"-->
                                                        <!--  placeholder="自定义金额" ></el-input-number> v-if="errorMsg != ''"-->
                                                            <!-- <span  class="errorMsg">{{errorMsg}}</span> -->

                                                    </template>
                                                </el-radio-group>

                                                <template v-if="inputAmount">
                                                    <el-input-number style="margin-left: 20px; margin-top:0px; width: 130px" placeholder="自定义金额" v-model="dataSelAmount" @keydown="handleDxChange" controls-position="right" @change="handleDxChange" ></el-input-number>
                                                    <el-button type="text" @click="inputAmount=false;errorMsg=''"style="margin-left:5px;">选择系统数据包</el-button>
                                                    <span  class="errorMsg">{{errorMsg}}</span>
                                                </template>

                                                <span style="display: block;color: #ff5733;margin-top: 10px;" v-if="dataSelAmount == 0">{{ dxDataList[dataSelNum - 1].amount }}元购 {{ dxDataList[dataSelNum - 1].num }}条短信</span>
                                                <span style="display: block;color: #ff5733;margin-top: 10px;" v-if="dataSelAmount > 0">{{ dataTitle }}</span>
                                            </template>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row v-if="stepActive != 0 ">
                                    <el-col :span="24">
                                        <el-form-item label="账单金额">
                                            <div v-html="billCurAmtStr"></div>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row v-if="stepActive != 0">
                                    <el-col :span="12">
                                        <el-form-item label="支付单号">
                                            <div class="bill_no_view">
                                                <template
                                                        v-if="subscribeDto.proSubInfo.billStatusValue > 5"
                                                >
                          <span style="margin-right: 30px">{{
                            subscribeDto.proSubInfo.billNo
                          }}</span>
                                                    <el-button @click="lookPayDetailAction"
                                                    >查看支付详情
                                                    </el-button
                                                    >
                                                </template>
                                                <template
                                                        v-if="subscribeDto.proSubInfo.billStatusValue == 5"
                                                >
                                                    <el-button
                                                            style="margin-right: 30px; color: #409eff"
                                                            @click="gotoPayAction"
                                                    >立即支付
                                                    </el-button
                                                    >
                                                    <el-button
                                                            @click="cancelPayAction"
                                                            v-if="stepActive == 1"
                                                    >取消订阅
                                                    </el-button
                                                    >
                                                </template>
                                            </div>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="账单状态">
                                            <span>{{ subscribeDto.proSubInfo.billStatusName }}</span>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row v-if="stepActive == 2">
                                    <el-col :span="24">
                                        <el-form-item label="产品备注">
                                            <span>平台运营人员将会与您联系，协助您完成以下事宜</span>
                                            <template v-for="(item, index) in proRemarkList">
                                                <span :key="index">{{ index }} {{ item.remark }}</span>
                                            </template>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row v-if="stepActive > 0">
                                    <el-col :span="24">
                                        <el-form-item label="订阅状态">
                                            <span>{{ subscribeDto.proSubInfo.spsStatusName }}</span>
                                            <template v-if="subscribeDto.proSubInfo.billStatusValue < 0">
                                                <!--<el-button style="margin-left: 10px; color: #409eff"-->
                                                <!--@click="repeatSubscription()" v-if="stepActive == 4"-->
                                                <!--icon="el-icon-document-copy"-->
                                                <!--&gt;重新订阅</el-button>-->
                                            </template>
                                            <el-button v-if="stepActive == 4" style="margin-left: 30px; color: #409eff"
                                                       @click="cntinuePayAction()">续费订阅
                                            </el-button>

                                            <el-tooltip effect="light" v-show="subscribeDto.proSubInfo.repSub"
                                                        :content="tooltipContent"
                                                        placement="bottom" popper-class="atooltip">
                                                <el-button style="margin-left: 10px; color: #409eff"
                                                           @click="openRepeatSubs()"
                                                           icon="el-icon-document-copy"
                                                >再订阅一个【{{subscribeDto.proSubInfo.spName}}】
                                                </el-button>
                                            </el-tooltip>


                                            <span style="display: block; color:#409eff"
                                            >已经订阅完成，可以使用系统</span>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row v-if="stepActive == 2 || stepActive == 4">
                                    <el-col :span="24">
                                        <div class="bottom_center_view">
                                            <el-button v-if="stepActive == 2" :disabled="true">
                                                安装部署中，请稍后
                                            </el-button
                                            >
                                            <!-- <el-button
                                                    v-else
                                                    type="primary"
                                                    @click="gotoMySysAction"
                                                    :loading="subscribeLoding"
                                            >进入我的系统
                                            </el-button -->
                                            >
                                        </div>
                                    </el-col>
                                </el-row>
                            </template>
                        </box>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div  class="footer_view" v-if="stepActive < 2">
            <template v-if="stepActive == 0">
                <div class="bottom_left_view">
                    <div v-html="billInfo.discountInfo">
                    </div>
                    <div v-if="subscribeDto.proSubInfo.typeValue == 3">
                        <span> 本次需要支付<span style="color:red"> {{amount}} </span>元</span>
                    </div>
                </div>
                <div class="bottom_right_view">
                    <el-button
                            type="primary"
                            @click="subscribeAction"
                            :disabled = "errorMsg!=''"
                            :loading="subscribeLoding"
                    >提交订阅
                    </el-button
                    >
                </div>
            </template>
            <template v-if="stepActive == 1">
                <div class="bottom_left_view">
                    <span v-html="billAllAmtStr" style="font-size: 14px"></span>
                </div>
                <div class="bottom_right_view">
                    <el-button
                            type="primary"
                            @click="lookAllSubscribeBillAction"
                            :loading="subscribeLoding"
                    >查看所有账单
                    </el-button
                    >
                </div>
            </template>
        </div>

        <el-dialog
                title="系统提醒"
                :visible.sync="relyMustTag"
                width="30%"
                :close-on-press-escape="false"
                :modal-append-to-body="false"
                :show-close="false"
                :close-on-click-modal="false"
        >
            <span>本产品的依赖产品：</span>
            <el-button style="margin:0px 5px;" type="text" v-for="(item,index) in relyList" :key="index" @click="gotoRelyMustProAction(1,item.id)">{{item.name}}</el-button>
            <span>未定阅，请您先订阅这些依赖产品。</span>
            <span slot="footer" class="dialog-footer">
        <el-button v-if="!reluMustClick" @click="closePage">退出订阅</el-button>
        <el-button type="primary" @click="gotoRelyMustProAction" v-if="reluMustClick">我已定阅</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import {gridMixin} from "@/mixins/grid-mixins";
    import {mapActions, mapGetters} from "vuex";
    import aEntityAPI from "@/api/sysAPI.js";
    import {formatDate, dateAdd, getMonthByTwoTimes} from "@/utils/utils.js";
    import {dateFormat} from "../../../../utils/utils";
import item from '../../../../components/selector/item';

    export default {
        mixins: [gridMixin],
        props: {
            id: {
                type: [String, Number],
            },
            subId: {
                type: [String, Number],
            },
        },
        computed: {
            ...mapGetters({
                userinfo: "user/userinfo",
                shipper: "user/shipper",
            }),
        },

        watch: {
            mountNum: function (before, after) {

                console.log("11111111")
                console.log(before)
                if (this.stepActive == 0 && before != 0) {
                    this.handChangeEndTime();

                    if (this.subscribeDto.proSubInfo.typeValue != 3) {
                        this.getBillInfo();
                    }
                    // this.getBillInfo();
                }
            },

            dataSelNum: function (before, after) {
                this.sumPrice = this.bdDataList[before - 1].amount;
                if (this.stepActive != 0 || before == 0) {
                    return;
                }
                var dataDict = null;
                if (this.bdTag) {
                    dataDict = this.bdDataList[before - 1];
                }
                if (this.dxTag) {
                    dataDict = this.dxDataList[before - 1];
                }
                if (dataDict != null) {
                    this.amount = dataDict.amount;
                    this.dataTimeNum = dataDict.num;
                    if (this.subscribeDto.proSubInfo.typeValue != 3) {
                        this.getBillInfo();
                    }
                }
            },

            $route(to, from) {
                let that = this;


                if (to.path.indexOf("saasSubscribeTab") > -1) {
                    if (to.params.stepActive > 0) {
                        that.getBdOrDxBaseDict();
                    }
                }

            },
        },

        data() {
            var that = this
            var needSubRemark = (rule, value, callback) => {
                if ((value == null || value == '') && that.repeat) {
                    callback(new Error('为了区分同产品订阅多次，需要填入订阅备注'));
                } else {
                    callback();
                }
            };

            var needMountNum = (rule, value, callback) => {

                if (!that.bdTag && !that.dxTag) {
                    //如果产品类型是服务时，则不验证
                    if (that.subscribeDto.proSubInfo.typeValue != 4) {

                        if (that.mountNum == 0 || that.mountNum == null) {
                            // this.$message({
                            //     showClose: true,
                            //     message: "请选择订阅时长",
                            //     type: "warning",
                            // });
                            callback(new Error('请选择订阅时长'));
                        } else if (that.mountNum < that.minMountNum) {
                            callback(new Error('该产品最少需订阅' + that.minMountNum + '个月'));
                        } else {
                            callback();
                        }

                    } else {
                      callback();
                    }
                }
            }

            return {
                inputAmount: false,
                errorMsg: '',
                rules: {
                    "proSubInfo.subRemark": [
                        {validator: needSubRemark, trigger: 'blur'}
                    ],
                    "proSubInfo.mountNum": [
                        {validator: needMountNum, trigger: 'change'}
                    ]
                },
                addTimeTag:false, //是否续订时间的标志，用于保存时，开始时间增加一天
                pageLoding: false,
                repeat: false,//是否强制订阅一个新产品
                renew: false, //是否续订模式
                reshunting:false,
                showMomth: true,
                sumPrice: 2000,//数据类消费额度
                showPrice: true,
                relyMustTag: false,
                relyMustMsg: "",
                relyList:[],
                allRelyList : [],
                spName: "",
                tooltipContent: "",
                spCode: "",
                isNew: false,
                dataSelNum: 0,
                dataSelAmount: null,
                bdTag: false,
                dxTag: false,
                dataTimeNum: 0, //订阅的次数
                reluMustClick: false,
                curId: null,
                new: '',
                curSubId: null, //订阅的ID
                subscribeLoding: false,
                tenantDomain: "", //基础Saas管理的tenant的所属domain
                stepActive: 0,
                dataTitle: null,
                dataNum: 0,
                allAmount: 0, //总金额
                amount: 0, // 折扣账单总金额
                selNums: 1, //选择订阅的数量
                mountNum: 0, //选择的月数
                minMountNum: 0, //最小选择的月数
                toDate: null, //延长的有效期
                billAllAmtStr: "",
                billCurAmtStr: "",
                billInfo: {},
                subscribeDto: {
                    hrSaaSTenantDomain: "",

                    tenantInfo: {},
                    proSubInfo: {
                        mountNum: 0,
                        subRemark: ""
                    },
                    unPayBillList: [],
                },
                mountArray: [
                    {value: 1, name: "1个月"},
                    {value: 2, name: "2"},
                    {value: 3, name: "3"},
                    {value: 4, name: "4"},
                    {value: 5, name: "5"},
                    {value: 6, name: "6"},
                    {value: 7, name: "7"},
                    {value: 8, name: "8"},
                    {value: 9, name: "9"},
                    {value: 10, name: "10"},
                    {value: 11, name: "11"},
                    {value: 12, name: "1年"},
                    {value: 24, name: "2年"},
                    {value: 36, name: "3年"},
                ],
                bdDataList: [         
                    // {value: 10, name: "100元", amount: 100, num: 2857, price: 1},
                    // {value: 20, name: "500元", amount: 500, num: 7142, price: 0.9},                    
                    // {value: 30, name: "1000元", amount: 500, num: 7142, price: 0.8},
                    // {value: 1, name: "2000元", amount: 2000, num: 2857, price: 0.7,linePrice:0.7},
                    // {value: 2, name: "5000元", amount: 5000, num: 7142, price: 0.7,linePrice:0.7},
                    // {value: 3, name: "10000元", amount: 10000, num: 16666, price: 0.6,linePrice:0.7}
                    // {value: 4, name: "15000元", amount: 15000, num: 25000, price: 0.6},
                    // {value: 5, name: "20000元", amount: 20000, num: 33333, price: 0.6},
                ],

                dxDataList: [
                    // {value: 1, name: "500元", amount: 500, num: 10000},
                    // {value: 2, name: "1000元", amount: 1000, num: 20000},
                    // {value: 3, name: "1500元", amount: 1500, num: 30000},
                    // {value: 4, name: "2000元", amount: 2000, num: 40000},
                ],
            };
        },

        methods: {
            ...mapActions(["page/closeCurrentPage"]),

            mountNumChanged(v) {
                this.mountNum = v;
                this.subscribeDto.proSubInfo.mountNum = v
            },

            //背景色
            checkTagColor(typeName) {
                // 工具(1), 资源(2),数据(3),服务(4);
                if (typeName == "工具") {
                    return "409EFF"; //蓝色
                } else if (typeName == "资源") {
                    return "#E6A23C"; //黄色
                } else if (typeName == "数据") {
                    return "#F56C6C"; //红色
                } else {
                    return "#67C23A"; //绿色
                }
            },

            //关闭当前页面
            closePage() {
                this.relyMustTag = false;
                this["page/closeCurrentPage"]({
                    ctx: this,
                    autoPre: false,
                });
                this.$router.push({
                    name: "subsribeProduct",
                });
            },

            //月份显示的控制
            disMounthSel(value) {
                if (value < this.minMountNum) {
                    return true;
                } else {
                    return false;
                }
            },

            //重复订阅
            openRepeatSubs() {
                var that = this;

                this.$router.push({
                    name: 'newSaasSubscribeTab',
                    params: {
                        id: this.curId + '',
                        code: this.subscribeDto.proSubInfo.spCode
                    }
                })

                that.stepActive = 0;
            },

            //续费订阅
            cntinuePayAction() {
                var that = this;
                //初始化数据
                that.stepActive = 0;
                that.amount = 0;
                that.dataSelNum = 1;
                that.mountNum = 0;

                var proSubInfo = JSON.parse(JSON.stringify(that.subscribeDto.proSubInfo));
                console.log(proSubInfo.spUnitValue)
                if (proSubInfo.spUnitValue > 4) {
                    that.stepActive = 0;
                    that.bdTag = true
                } else {
                    that.stepActive = 0;
                }
                // proSubInfo.startTime = that.subscribeDto.proSubInfo.billStartTime ? that.subscribeDto.proSubInfo.billStartTime : null;
                // proSubInfo.endTime = that.subscribeDto.proSubInfo.billEndTime  ? that.subscribeDto.proSubInfo.billEndTime : null;;
                proSubInfo.fromTime = null;
                proSubInfo.toTime = null;
                that.subscribeDto.proSubInfo = proSubInfo;
            },

            repeatSubsAction() {
                //初始化数据
                this.stepActive = 0;

                sessionStorage.setItem("detail", true);
                this.amount = 0;
                this.dataSelNum = 1;
                this.mountNum = 0;
                // var proSubInfo = JSON.parse(JSON.stringify(this.subscribeDto.proSubInfo));
                var proSubInfo = this.subscribeDto.proSubInfo
                proSubInfo.toTime = null;
                proSubInfo.fromTime = null;
                proSubInfo.endTime = null;
                // proSubInfo.subRemark = "";

                this.$set(this.subscribeDto.proSubInfo, "subRemark", "")
                proSubInfo.newStartTime = null;
                // this.subscribeDto.proSubInfo = proSubInfo;
            },

            // 计算结束时间
            handChangeEndTime() {
                var that = this;
                var toDateT = formatDate(new Date(), "yyyy-MM-dd");
                if (that.subscribeDto.proSubInfo.newStartTime != null && that.subscribeDto.proSubInfo.newStartTime != "") {
                    toDateT = dateAdd("m",that.mountNum,new Date(that.subscribeDto.proSubInfo.newStartTime));
                    //续订的，开始日期加一天
                    that.addTimeTag = true;
                } else {
                    that.$set(that.subscribeDto.proSubInfo, "newStartTime", toDateT);
                    toDateT = dateAdd("m", that.mountNum, new Date());
                    //如果是新订阅的 截止日期此处需要减一天
                    toDateT = dateAdd("d", -1, toDateT);
                }
                
                //计算结束时间
                that.toDate = formatDate(toDateT, "yyyy-MM-dd");
                //计算金额
                var allAmount = that.mountNum * this.subscribeDto.proSubInfo.unitPrice;
                that.allAmount = parseFloat(allAmount).toFixed(2);
                that.amount = that.allAmount;
                
            },

          //处理短信
          handleDxChange(amount) {

            var flag= false;
            
            if (this.dxDataList && this.dxDataList.length > 0) {
                for (let i = 0; i < this.dxDataList.length; i++) {
                    var item = this.dxDataList[i];
                    if (amount >= item.amount ){
                        flag = true;//当前金额大于最小数据包，有效

                        this.amount = amount;

                        if (amount > item.amount && i == this.dxDataList.length - 1 ){
                            this.dataTitle = amount+'元购 '+amount/(item.amount/item.num)+'条短信';
                            this.dataNum = (amount/(item.amount/item.num))        
                            break;          
                        }

                        if (amount >  item.amount && amount <  this.dxDataList[i+1].amount ){
                            this.dataTitle = amount+'元购 '+amount/(item.amount/item.num)+'条短信';
                            this.dataNum = (amount/(item.amount/item.num))
                            break;
                        }
                    }
                }
            }
            

            // if (amount < item.amount ){
                //   this.dataTitle = amount+'元购 '+amount/this.subscribeDto.proSubInfo.unitPrice+'条短信';
                //   this.dataNum = (amount/this.subscribeDto.proSubInfo.unitPrice)
                //   break;
                // }
                
             


            //金额不足
            if (!flag){
                this.errorMsg = "自定义金额不可低于最小数据包"              
            } else {
                this.errorMsg = ""
            }
          },

          //处理北斗
          handleBdChange(amount) {
            console.log("======this.bdDataList=======")

            // this.amount = amount;
            var flag= false;

            console.log(this.bdDataList)
            if (this.bdDataList && this.bdDataList.length > 0) {
              for (let i = 0; i < this.bdDataList.length; i++) {
                var item = this.bdDataList[i];
                if (amount >= item.amount ){
                    flag = true;    
                    this.amount = amount;
            
                    if (amount > item.amount && i == this.dxDataList.length - 1 ){
                        this.dataTitle = '满赠活动：套餐包用于车辆实时定位或历史轨迹查询，折扣单价为定位'+item.price+'元/天/车、轨迹'+item.linePrice+'元/天/车';
                        this.dataNum = (amount/(item.price))
                        break;
                    }
                    if (amount >  item.amount && amount <  this.dxDataList[i+1].amount ){
                        this.dataTitle = '满赠活动：套餐包用于车辆实时定位或历史轨迹查询，折扣单价为定位'+item.price+'元/天/车、轨迹'+item.linePrice+'元/天/车';
                        this.dataNum = (amount/(item.price))
                        break;
                    }
                }
              }
            }

            //金额不足
            if (!flag){
                this.errorMsg = "自定义金额不可低于最小数据包"              
            } else {
                this.errorMsg = ""
            }

            // if (flag == true){
            //   this.$message({
            //     showClose: true,
            //     message: "填写的北斗数据包金额不可低于最低金额",
            //     type: "warning",
            //   });
            // }

          },
          
          handleDxRadio(value) {
            console.log(value);
            this.dataSelAmount = null;
          },
            //查看支付详情
            lookPayDetailAction() {
                this.$router.push({
                    name: "subscribePayStep",
                    params: {
                        ids: this.subscribeDto.proSubInfo.spsBillId + "",
                    },
                });
            },

            //前往我的依赖订阅
            gotoRelyMustProAction(type,relyId) {
                if(type == 1){
                     this.reluMustClick = true;

                    this.$router.push({
                        name: "saasSubscribeTab",
                        params: {id: relyId},
                    });
                }else{
                    if (this.reluMustClick) {
                    this.relyMustTag = false;
                    this.getBdOrDxBaseDict();
                } 
                }
                
            },

            //进入我的系统
            gotoMySysAction() {
                console.log(this.subscribeDto.hrSaaSTenantDomain)
                console.log(this.subscribeDto.tenantInfo.domain)

                var urls = this.subscribeDto.hrSaaSTenantDomain || this.subscribeDto.tenantInfo.domain;
                if (urls != null && urls != "") {
                    var urlList = urls.split(",");
                    var host = urlList[0];
                    host = window.location.protocol + "//" + host + "/login.html#/login";
                    window.open(host);
                } else {
                    this.$message({
                        showClose: true,
                        message: "域名为空",
                        type: "warning",
                    });
                }
            },

            //查看所有账单
            lookAllSubscribeBillAction() {
                this.$router.push({name: "saasProSubBillList"});
            },

            //立即支付
            gotoPayAction() {
                this.$router.push({
                    name: "subscribePayStep",
                    params: {
                        ids: this.subscribeDto.proSubInfo.spsBillId + "",
                    },
                });
            },

            //获取订阅产品的基本数据
            queryProductInfo() {
                aEntityAPI.saasRequest("UserProductSubscribeService", "queryProductInfo", {
                    productCode: this.spCode,
                    tenantInfoId: this.subscribeDto.tenantInfo.id,
                }).then((res) => {
                    if (res.data.success) {
                        this.subscribeDto.proSubInfo = res.data.data.product;
                        this.subscribeDto.tenantInfo = res.data.data.tenantInfo;
                    }
                })
            },

            //取消订阅
            cancelPayAction() {
                let that = this;
                this.$confirm("您确认取消订阅吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        that.cancelPaySubscribe();
                    })
                    .catch(() => {
                    });
            }
            ,

            //获取折扣信息
            getBillInfo() {
                if (this.dataSelNum < 0) {
                    return;
                }
                let that = this;
                var dataSelItem = null;
                if (this.bdTag) {
                    dataSelItem = this.bdDataList[this.dataSelNum - 1];
                }
                if (this.dxTag) {
                    dataSelItem = this.dxDataList[this.dataSelNum - 1];
                }
                var amount = that.amount;
                var curDate = null;
                if (this.dxTag || this.bdTag) {
                    amount = dataSelItem.amount;
                } else {
                    curDate = formatDate(new Date(), "yyyy-MM-dd");
                }
                var startTimeT = that.subscribeDto.proSubInfo.newStartTime ? that.subscribeDto.proSubInfo.newStartTime : curDate;
                var newDateTimeStr = "";
                if(this.addTimeTag){
                     var newDateTime = dateAdd("d",1,new Date(startTimeT)); //加一天
                    newDateTimeStr = formatDate(newDateTime, "yyyy-MM-dd");
                }else{
                    newDateTimeStr = startTimeT;
                }
               
                var subscribe = {
                    count: dataSelItem ? dataSelItem.num : null,
                    duration: this.mountNum ? this.mountNum : null,
                    product: {
                        id: that.subscribeDto.proSubInfo.spId,
                        type: {value: that.subscribeDto.proSubInfo.typeValue}
                    },
                    amount: amount,
                    toTime: that.toDate ? that.toDate : null,
                    fromTime:newDateTimeStr,
                    unitPrice: that.subscribeDto.proSubInfo.unitPrice,
                    unit: {value: that.subscribeDto.proSubInfo.spUnitValue},
                };

                aEntityAPI
                    .saasRequest("UserProductSubscribeService", "preCalcCgioSave", {
                        bills: JSON.stringify([subscribe]),
                        "@TenantDomain": this.tenantDomain,
                    })
                    .then((res) => {
                        that.subscribeLoding = false;
                        if (res.data.success) {
                            this.billInfo = res.data.data;
                        }
                    })
                    .catch((res) => {
                        this.subscribeLoding = false;
                    });
            }
            ,

            //取消当前的订阅信息
            cancelPaySubscribe() {
                let that = this;
                aEntityAPI
                    .saasRequest("SaasTenantBillService", "billRev", {
                        id: this.subscribeDto.proSubInfo.spsBillId,
                        "@TenantDomain": this.tenantDomain,
                    })
                    .then((res) => {
                        that.subscribeLoding = false;
                        if (res.data.success) {
                            this.getBdOrDxBaseDict();
                            this.$message({
                                showClose: true,
                                message: "当前订阅已取消",
                                type: "success",
                            });
                        } else {
                            this.$message({
                                showClose: true,
                                message: res.data.msg ? res.data.msg : "取消订阅失败",
                                type: "warning",
                            });
                        }
                    })
                    .catch((res) => {
                        this.subscribeLoding = false;
                    });
            }
            ,
            //提交申请订阅
            subscribeAction() {
                
                if(this.errorMsg!=''){
                    console.log('输入的金额过小')
                    return;
                }
                    
                if(this.inputAmount==true){
                    if(this.amount==null || this.amount <= 0){
                        console.log('输入的金额过小')
                        return;
                    }
                }           

                let that = this;

                that.$refs.dataDictRef.validate((valid) => {
                    if (valid) {

                        if (that.bdTag || that.dxTag) {
                            if (that.dataSelNum == null || that.dataSelNum == "") {
                                this.$message({
                                    showClose: true,
                                    message: "请选择订阅数量",
                                    type: "warning",
                                });
                                return;
                            }
                        }

                        //比较当前续订的时间是否币依赖产品的时间长
                        if (that.allRelyList != null && that.allRelyList.length > 0) {
                            var msg = "";
                            var haveOneTag = false;
                            that.allRelyList.forEach(item => {
                                if (!that.dxTag && !that.bdTag && that.toDate > item.relyEndTime) {
                                // msg = msg + 
                                //     "订阅时长不能超过依赖产品[" +
                                //     item.name +
                                //     "]的有效期：" +
                                //     item.relyEndTime + "\n";
                                
                            }else{
                                haveOneTag = true;
                            }
                            });
                            // if(!haveOneTag){
                            //     this.$message({
                            //         showClose: true,
                            //         message: msg,
                            //         type: "warning",
                            //     });
                            // }
                            
                        }

                        //获取数据包选择的金额
                        var dataSelItem = null;
                        if (this.bdTag) {
                            dataSelItem = this.bdDataList[this.dataSelNum - 1];
                        }
                        if (this.dxTag) {
                            dataSelItem = this.dxDataList[this.dataSelNum - 1];
                        }
                        var amount = that.amount;
                        var curDate = formatDate(new Date(), "yyyy-MM-dd");
                        if (this.dxTag || this.bdTag) {
                            amount = dataSelItem.amount;
                            curDate = null;
                        }
                        if (this.dataSelAmount > 0 ) {
                            amount = this.dataSelAmount;
                            curDate = null;
                        }

                        if (amount == null) {
                            this.$message({
                                showClose: true,
                                message: "账单金额异常，请刷新重新操作",
                                type: "warning",
                            });
                            return;
                        }

                        var subscribe = {
                            id: that.subscribeDto.proSubInfo.spsId
                                ? that.subscribeDto.proSubInfo.spsId
                                : null,
                            // count: dataSelItem ? dataSelItem.num : null,
                            count: this.dataNum ? this.dataNum : (dataSelItem ? dataSelItem.num : null),
                            duration: this.mountNum ? this.mountNum : null,
                            product: {id: that.subscribeDto.proSubInfo.spId},
                            amount: amount,
                            subRemark: this.subscribeDto.proSubInfo.subRemark = "" ? null : this.subscribeDto.proSubInfo.subRemark,

                            unitPrice: that.subscribeDto.proSubInfo.unitPrice,
                            unit: {value: that.subscribeDto.proSubInfo.spUnitValue},
                        };

                        debugger

                        //北斗和短信
                        if (!this.bdTag && !this.dxTag) {
                            var startTimeT = that.subscribeDto.proSubInfo.newStartTime ? that.subscribeDto.proSubInfo.newStartTime : curDate;
                            var newDateTimeStr = "";
                            if(this.addTimeTag){
                                //续费订阅的开始时间加一天
                                var newDateTime = dateAdd("d",1,new Date(startTimeT)); //加一天
                                newDateTimeStr = formatDate(newDateTime, "yyyy-MM-dd");
                            }
                            subscribe.toTime = that.toDate ? that.toDate : null;
                            subscribe.fromTime = newDateTimeStr;
                            
                        }
                        that.subscribeLoding = true;
                        aEntityAPI
                            .saasRequest("UserProductSubscribeService", "saasSubscribeApply", {
                                tentInfo: JSON.stringify(this.subscribeDto.tenantInfo),
                                subscribe: JSON.stringify(subscribe),
                                "@TenantDomain": this.tenantDomain,
                            })
                            .then((res) => {
                                that.subscribeLoding = false;
                                if (res.data.success) {
                                    that.stepActive = 2;

                                    this.$message({
                                        showClose: true,
                                        message: "提交申请订阅成功",
                                        type: "success",
                                    });
                                    sessionStorage.removeItem("detail")
                                    /*
                                        设置本页面的参数
                                    */
                                    this.curSubId = res.data.data.id
                                    this.renew = false;
                                    this.repeat = false;
                                    this.getBdOrDxBaseDict()
                                } else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.msg ? res.data.msg : "提交申请订阅失败",
                                        type: "warning",
                                    });
                                }
                            })
                            .catch((res) => {
                                this.subscribeLoding = false;
                            });
                    }
                });
            }
            ,

            //获取订阅产品的明细信息
            getSubscribeDetail() {
                let that = this;
                that.pageLoding = true;
                aEntityAPI
                    .saasRequest("UserProductSubscribeService", "getExpSubscribeInfo", {
                        proId: that.curId,
                        subId: that.curSubId,
                        "@TenantDomain": that.tenantDomain,
                    })
                    .then((res) => {
                        that.pageLoding = false;
                        if (res.data.success) {
                            var dictT = res.data.data;
                            if (dictT.billCurAmtStr != null) {
                                that.billCurAmtStr = dictT.billCurAmtStr;
                            }
                            if (dictT.billAllAmtStr != null) {
                                that.billAllAmtStr = dictT.billAllAmtStr;
                            }
                            
                            //hrsaas的业务系统域名 console.log(dictT.hrSaaSTenantDomain)
                            this.subscribeDto.hrSaaSTenantDomain = dictT.hrSaaSTenantDomain

                            if (dictT.tenantInfo != null) {
                                that.subscribeDto.tenantInfo = dictT.tenantInfo;
                            }
                            if (dictT.proInfo != null) {
                                this.tooltipContent = "本产品支持多次订阅,你可以另外再订阅一个【" + dictT.proInfo.spName + "】产品";
                                that.subscribeDto.proSubInfo = dictT.proInfo;
                                if (dictT.proInfo.spCode.indexOf("data_,dingwei,guiji,trace_forSaas") != -1) {
                                    that.bdTag = true;
                                }
                                if (dictT.proInfo.spCode.indexOf("shunting") != -1) {
                                    that.bdTag = true;
                                }
                                if (dictT.proInfo.spCode.indexOf("data_,send_sms") != -1) {
                                    that.dxTag = true;
                                }
                                if (
                                    dictT.proInfo.relyList != null && dictT.proInfo.relyList.length > 0
                                  ) {
                                    
                                    that.allRelyList = dictT.proInfo.relyList;
                                 var expRely = [];
                                 var haveOneTag = false;
                                 that.allRelyList.forEach(item => {
                                     if(item.relyspsStatus == null || item.relyspsStatus < 4){
                                        expRely.push(item);
                                     }else{
                                         haveOneTag = true;
                                     }
                                     
                                 });
                                 if(!haveOneTag ){
                                          that.relyMustTag = true;
                                            that.reluMustClick = false;
                                            that.relyList = expRely;
                                    }
                                      
                                }

                                //如果订阅价格为元 或者 个  时 订阅时长不显示
                                if (that.subscribeDto.proSubInfo.unit == 2 || that.subscribeDto.proSubInfo.unit == 3) {
                                    that.showMomth = false;
                                }

                                //如果是数据类型 则 订阅价格不显示数据
                                if (that.subscribeDto.proSubInfo.typeValue == 3) {
                                    this.showPrice = false;
                                }

                                if (dictT.proInfo.minNum != null) {
                                    that.minMountNum = dictT.proInfo.minNum;
                                }
                                if (
                                    dictT.proInfo.endNowNum != null &&
                                    dictT.proInfo.endNowNum < 0
                                ) {
                                    that.subscribeDto.overTimTitle = "已过期";
                                    that.subscribeDto.overTims = -dictT.proInfo.endNowNum;
                                } else {
                                    that.subscribeDto.overTimTitle = "剩余";
                                    that.subscribeDto.overTims = dictT.proInfo.endNowNum;
                                }
                                if (dictT.proInfo.billAmount != null) {
                                    that.amount = dictT.proInfo.billAmount;
                                }
                                if (dictT.proInfo.subMonths != null && dictT.proInfo.spsStatusValue > 0) {
                                    that.mountNum = dictT.proInfo.subMonths;
                                } else {
                                    that.mountNum = that.minMountNum > 0 ? that.minMountNum : 1;
                                }
                                if (dictT.proInfo.spsStatusValue < 0) {
                                    that.stepActive = 0;
                                }

                                if (dictT.proInfo.spsStatusValue == 10) {
                                    that.stepActive = 4;
                                } else if (dictT.proInfo.spsStatusValue == 5) {
                                    that.stepActive = 2;
                                } else if (dictT.proInfo.spsStatusValue == 4 || dictT.proInfo.spsStatusValue == 3) {
                                    that.stepActive = 1;
                                } else {
                                    that.stepActive = 0;
                                }
                                if (that.stepActive == 0 && (that.bdTag || that.dxTag)) {
                                    this.dataSelNum = 1;
                                    if (this.subscribeDto.proSubInfo.typeValue != 3) {
                                        that.getBillInfo();
                                    }
                                    // that.getBillInfo();
                                }

                                //是以续订的url进入
                                that.$route.params.stepActive = that.stepActive;

                                if (this.renew) {
                                    this.cntinuePayAction();
                                }

                                if (this.repeat) {
                                    this.repeatSubsAction();
                                }
                            }

                        } else {
                            that.$message({
                                showClose: true,
                                message: res.data.msg ? res.data.msg : "查询产品订阅数据失败",
                                type: "warning",
                            });
                        }
                    })
                    .catch((res) => {
                        that.pageLoding = false;
                    });
            }
            ,

            //获取北斗或者短信的基础数据
        getBdOrDxBaseDict(){
            let that = this;
            aEntityAPI.saasRequest("UserProductSubscribeService", "getBdOrDxBaseDict", {}).then((res) => {
                if (res.data.success) {
                      if(res.data.data != null && res.data.data.bdList != null && !this.reshunting){
                        var dataList = [];
                        for(var i = 0; i <res.data.data.bdList.length;i++ ){
                                var item =  res.data.data.bdList[i];
                                var priceArray = item.value.split(",");
                                if(priceArray != null && priceArray.length == 3){
                                    var dict = {
                                        value:i + 1,
                                        name: item.name, 
                                        amount: item.num_value, 
                                        num: priceArray[0], 
                                        price: priceArray[1],
                                        linePrice:priceArray[2],
                                    };
                                dataList.push(dict);
                                }
                            
                        }
                        that.bdDataList = dataList;
                      }
                      if(res.data.data != null && res.data.data.dcList != null && this.reshunting){
                        var dataList = [];
                        for(var i = 0; i <res.data.data.dcList.length;i++ ){
                                var item =  res.data.data.dcList[i];
                                var priceArray = item.value.split(",");
                                if(priceArray != null && priceArray.length == 3){
                                    var dict = {
                                        value:i + 1,
                                        name: item.name, 
                                        amount: item.num_value, 
                                        num: priceArray[0], 
                                        price: priceArray[1],
                                        linePrice:priceArray[2],
                                    };
                                dataList.push(dict);
                                }
                            
                        }
                        that.bdDataList = dataList;
                      }
                      if(res.data.data != null && res.data.data.dxList != null){
                        var dataList = [];
                         for(var i = 0; i <res.data.data.dxList.length;i++ ){
                                var item =  res.data.data.dxList[i];
                            var dict = {
                                value:i + 1,
                                name: item.name, 
                                amount: item.num_value, 
                                num: item.value, 
                            };
                            dataList.push(dict);
                        }
                        that.dxDataList = dataList;
                      }
                      console.log(that.bdDataList,'aaaaaa');
                }
                
                that.getSubscribeDetail();
                       
            }).catch((res) => {
                   that.getSubscribeDetail(); 
            });
        },
        },
        // 挂载查询
        beforeMount:

            function () {
            }

        ,

        


        mounted() {
            let that = this;
            if (this.$route.params && this.$route.params.id != null) {
                this.curId = this.$route.params.id;
            }
            if (this.$route.params && this.$route.params.subId != null) {
                this.curSubId = this.$route.params.subId;
            }
            if (this.$route.params && this.$route.params.new != null) {
                this.new = this.$route.params.new;
            }
            if (this.$route.params && this.$route.params.code != null) {
                this.spCode = this.$route.params.code;
            }
            this.renew = this.$route.path.indexOf("Renew") > -1
            this.reshunting = this.$route.path.indexOf("shunting") > -1
            this.repeat = this.$route.path.indexOf("newSaasSubscribeTab") > -1
            that.pageLoding = true;
            aEntityAPI
                .saasRequest("MasterTenantService", "getSaasAdminSysTenantDomain", {
                    "@TenantDomain": this.tenantDomain,
                })
                .then((res) => {
                    this.pageLoding = false;
                    if (res.data.success) {
                        this.tenantDomain = res.data.data.tenantDomain.split(",")[0];
                        // debugger
                        if (sessionStorage.getItem("detail")) {
                            this.queryProductInfo();
                            sessionStorage.removeItem("detail")
                        } else {
                            this.getBdOrDxBaseDict();
                        }
                    }
                }).catch((res) => {
                that.pageLoding = false;
            });
        }
        ,

        destroyed() {
        }
        ,
    };
</script>

<style>

    .atooltip.el-tooltip__popper[x-placement^="bottom"] .popper__arrow {
        border-top-color: #E5E5E5;
        /*border: none;*/
        /*border-style: none;*/
    }

    .atooltip.el-tooltip__popper[x-placement^="bottom"] .popper__arrow:after {
        border-top-color: #E5E5E5;
        /*border-style: none;*/
    }

    .atooltip {
        background: #E5E5E5 !important;
        border: none;
        /*border-style: none;*/
    }

    .zq_sure_subscribe {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .bottom_center_view {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .zq_sure_subscribe .top_view {
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #999;
        margin-bottom: 40px;
    }

    .zq_sure_subscribe .body_view {
        background-color: #fff;
        flex: 2;
        margin-bottom: 20px;
        background-color: white;
         margin-bottom: 90px;
    }

    .zq_sure_subscribe .title_step {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .zq_sure_subscribe .title_step_lab {
        font-size: 20px;
        color: black;
    }

    .zq_sure_subscribe .bottom_view {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
        
    }

    .zq_sure_subscribe .footer_view {
        background-color: #fff;
        height: 80px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
    }

    .zq_sure_subscribe .bottom_left_view {
        font-size: 14px;
        color: black;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 20px;
        margin-right: 40px;
    }

    .zq_sure_subscribe .bottom_right_view {
        display: flex;
        flex-direction: row;
        padding-right: 40px;
    }

    /* 滚动条设计 */

    .body_view::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
    }

    .body_view::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #b3b0b0;
    }

    .body_view::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        background: #ededed;
    }

    .zq_sure_subscribe .box_view_top {
        border: 1px solid #ddd;
        border-bottom: 1px solid #fff;
    }

    .zq_sure_subscribe .box_view_bottom {
        border: 1px solid #ddd;
        border-top: 1px solid #fff;
    }

    .zq_sure_subscribe .amt_color {
        color: #ff502e;
    }

    .errorMsg {
        margin-top: 0px;
        margin-left: 10px;
        display: inline-block;
        color: #ff0000;
        width: 210px;
        line-height: 26px;
        font-size: 12px;
    }

    .singleLineTxt {
        display: block; /* 需要 */
        white-space: nowrap; /* 过长部分不换行 */
        text-overflow: ellipsis; /* 过长部分展示省略号 */
        overflow: hidden; /* 隐藏过长部分 */
    }
</style>

<style scoped>
  /* reset element style */
  .zq_sure_subscribe :deep(.el-radio-group) {
    display: flex;
  }
</style>
