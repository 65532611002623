import Vue from "vue";

export default {
  /**
   * 登录
   * @param {*mobile} mobile
   */
  checkAdmin(mobile) {
    return Vue.http.post("checkAdmin", { mobile: mobile });
  },

  /**
   * 是否需要验证码
   * @param {*mobile} mobile
   */
  needVerify(mobile) {
    return Vue.http.post("needVerify", { mobile: mobile });
  },

  /**
   * 获取注册验证码
   */
  getSmsCode(params) {
    return Vue.http.post("getSmsCode", params);
  },

  /**
   * 登录
   * @param {*loginName,password} creds
   */
  login(creds) {
    return Vue.http.post("login", creds);
  },

  /**
   * 获取登录二维码
   *
   */
  getLoginQrCode(loginDto) {
    return Vue.http.post("getLoginQrCode", { loginDto: loginDto });
  },
  /**
   *
   */

  requestHome() {
    return Vue.http.post("requestHome");
  },

  /**
   *
   * @returns
   */
  getHrSaaSDomain() {
    return Vue.http.post("reg/getHrSaaSDomain");
  },

  getLoginBanner(param) {
    return Vue.http.post("api/WebMsgService/getLoginBanner", param);
  },
  /**
   * 登出
   */
  logout() {
    return Vue.http.post("logout", {});
  },

  saasRequest(svc, method, params) {
    return Vue.http.post("api/" + svc + "/" + method, params, { headers: { TenantDomain: window.location.hostname } });
  },
  /**
   * 新手机号获取验证码
   */
  reqNewMobileVerify(params) {
    return Vue.http.post("api/UserAuthService/reqNewMobileVerify", params);
  },

  /**
   * 修改手机号
   */
  changeMobile(params) {
    return Vue.http.post("api/UserAuthService/changeMobile", params);
  },

  /**
   * 修改密码
   * @param {*} params
   */
  chagePassword(params) {
    return Vue.http.post("api/UserAuthService/changePassword", params);
  },

  /**
   * 获取登录用户信息
   * @param {*} params
   */
  getLastLoginHistory(userId) {
    return Vue.http.post("api/UserAuthService/getLastLoginHistory", { userId: userId });
  },

  /**
   * 获取历史登录日志
   * @param {*} params
   */
  findLoginHist(params) {
    return Vue.http.post("api/UserAuthService/findLoginHist", params);
  },

  /**
   * 修改用户头像
   * @param {*} userId
   * @param {*} fileId
   */
  updateUserProfileImg(userId, fileId) {
    return Vue.http.post("api/UserAuthService/updateUserProfileImg", {
      hostUserId: userId,
      fileId: fileId,
    });
  },

  /**
   * 获取注册验证码
   */
  reqBeforeVerify() {
    return Vue.http.post("reg/reqBeforeVerify", {});
  },

  getCurrentSaaSTenantInfo(params) {
    return Vue.http.post("reg/getCurrentTenantInfo", params);
  },
  checkImgVerifyCode(params) {
    return Vue.http.post("reg/checkImgVerifyCode", {
      registImgCode: params,
    });
  },

  /**
   * 获取注册验证码
   */
  getSmsVerifyCode(params) {
    return Vue.http.post("reg/getSmsVerifyCode", params);
  },

  /**
   * 校验填写的手机号是否注册过
   * @param {*} params
   */
  checkMobileExist(params) {
    return Vue.http.post("reg/checkMobileExist", params,{ headers: { TenantDomain: window.location.hostname } });
  },

  /**
   * 注册提交
   */
  registSubMit(params) {
    return Vue.http.post("reg/registSubMit", params);
  },
  /**
   * 注册提交(saas)
   */
  registSaasSubMit(params) {
    return Vue.http.post("reg/registSaasSubMit", params);
  },
  fileSaaSSubmit(params) {
    return Vue.http.post("reg/fileSaaSSubmit", params);
  },
  protocolSaaSSubmit(params) {
    return Vue.http.post("reg/protocolSaaSSubmit", params);
  },

  resetPassWord(params) {
    return Vue.http.post("reg/resetPassWord", params);
  },
  getUserUnreadMsgCount(userId) {
    return Vue.http.post("api/WebMsgService/getUserUnreadMsgCount", {
      userId: userId,
    });
  },
  getUnreadMsgTop5(userId) {
    return Vue.http.post("api/WebMsgService/getUnreadMsgTop5", {
      userId: userId,
    });
  },
  queryByMobile(mobile) {
    return Vue.http.post("api/UserAuthService/queryByMobile", {
      mobile: mobile,
    });
  },
  protocolSaaSPreview(templateCode, data) {
    return Vue.http.post("api/PrcTransportService/preview", {
      templateCode: templateCode,
      data: data,
    });
  },
  getMenuByCode(code) {
    return Vue.http.post("api/MenuService/getMenuByCode", {
      code: code,
    });
  },
  readMsgById(ids) {
    return Vue.http.post("api/WebMsgService/readMsgById", { ids: ids });
  },
  initAppConfig() {
    const clsList = ["CompanyConfig", "ESignConfig", "CertConfig"];
    return Vue.http.post("api/AppConfigService/getConfigsByClsList", { clsList: JSON.stringify(clsList) });
  },

  initOrderRecConfig() {
    return Vue.http.post("api/AppConfigService/getConfigByCls", { cls: "OrderRecConfig" });
  },
  /**
   * 获取服务协议
   * @param {} params
   */
  getPrcTransport(params) {
    return Vue.http.post("reg/getPrcTransport", params);
  },
  /**
   * 获取服务协议-无船
   * @param {} params
   */
  getShipContract(params) {
    return Vue.http.post("reg/getShipContract", params);
  },

  /**
   * 产品注册协议
   * @param {} params
   */
  getProductContract(params) {
    return Vue.http.post("reg/getProductContract", params);
  },

  /**
   * 获取开通的产品
   * @param {} params
   */
  getUserProducts(params) {
    return Vue.http.post("reg/getUserProducts", params);
  },
  /**
   * 刷新客户缓存
   */
  refreshCustomer() {
    return Vue.http.post("refreshCustomer", {});
  },
  /**
   * 切换身份 刷新shipper
   */
  refreshShipper(shipperId) {
    return Vue.http.post("refreshShipper", { shipperId: shipperId });
  },

  /**
   * 获取用户管辖用户
   * @param {*} params
   */
  findOrganUser(params) {
    return Vue.http.post("api/UserAuthService/findOrganUser", params);
  },
  /**
   * 刷新客户缓存
   */
  findGfzCustomers(start, query) {
    // 客户下拉,查询条件
    const params = {
      start: start,
      limit: 10,
      cls: "ShipperCustomer",
      fields: ["id", "name", "shipperId", "shipperName"],
      q: {
        name: query,
      },
    };
    return {
      qp: JSON.stringify(params),
    };
    return Vue.http.post("api/TdOrderInitService/findCustomerPage", { qp });
  },
  /**
   * 获得用户更新后的数据
   */
  getUserData() {
    return Vue.http.post("getUserData", {});
  },
  /**
   ** 获得账户信息
   */
  getAccountInfo(domain) {
    return Vue.http.post(
      `api/UserProductSubscribeService/getUserInfo?tenantDomain=${domain}`,
      {},
      { headers: { TenantDomain: window.location.hostname } },
    );
  },

  /**
   *通用服务调用
   * @param {Object} svc
   * @param {Object} method
   * @param {Object} params
   */
  request(svc, method, params) {
    return Vue.http.post("api/" + svc + "/" + method, params);
  },

  /**
   *通用服务调用
   * @param {Object} svc
   * @param {Object} method
   * @param {Object} params
   */
  saasRequest(svc, method, params) {
    return Vue.http.post("api/" + svc + "/" + method, params, { headers: { TenantDomain: window.location.hostname } });
  },

  /**
   ** 成本审核数量
   */
  getCostAuditCount() {
    return Vue.http("api/ChargeService/auditCount");
  },
};
