import Vue from 'vue'
export {Vue} 


import AddressText from '@/components/addressText/address.vue';
import Box from '@/components/box/box.vue';
import Grid from '@/components/Grid.vue';
import ElButton from '@/components/rewrite-ele/button.vue';
import Pagination from '@/components/rewrite-ele/pagination/pagination'
import ElementUI from 'element-ui';
// import Table from '@/components/rewrite-ele/table/index.js'
// import TableColumn from '@/components/rewrite-ele/table-column/index.js'

import Menu from '@/components/rewrite-ele/menu/index.js';
import Submenu from '@/components/rewrite-ele/menu/sub-menu-index.js';
import MenuItem from '@/components/rewrite-ele/menu/menu-item-index.js';
import MenuItemGroup from '@/components/rewrite-ele/menu/menu-item-group-index.js';

import GsArea from '@/components/area/gsarea.vue';
import ZbArea from '@/components/zbarea/area.vue';
import GsSelector from '@/components/selector/selector.vue';
import EnumSelector from '@/components/enum-selector/enum.vue';
import ExportColFilter from '@/components/exportColFilter.js'
import GsIcon from '@/components/gs-icon/icon.vue'
import dragdrop from '@/directives/dragAndDrop/index.js'
import http from '@/plugins/http/http'
import permission from '@/directives/permissions/index.js' // 权限判断指令
import UdaSelector from '@/components/uda-selector/udaSelector.vue';
import MapChsPoint from '@/components/mapChsPoint/mapChsPoint.vue';
import ZbrlMapChsPoint from '@/components/zbrlMapChsPoint/zbrlMapChsPoint.vue';
import * as echarts from 'echarts';
import '@/components/jsmpeg.min.js';

import HTable from '@/components/HTable/HTable.vue';

import '@/utils/xmlHttpRequestHook.js'

// 图表只在少数页面使用 建议 按需应用
// import VCharts from 'v-charts'
// Vue.use(VCharts)

//unmy-ui虚拟表格
// import 'umy-ui/lib/theme-chalk/index.css';
import { UTable, UTableColumn } from 'umy-ui';
import '@/style/theme/index.css'
import '@/style/gs-icon/iconfont.css';
import '@/style/index.scss';
import '@/style/siyuan.less';//引入思源字体
import '@/style/DINCOND.css';//引入数字包

import '@/style/logo.less';//引入logo字体
import VueCropper from 'vue-cropper';

Vue.component(UTable.name, UTable);
Vue.component(UTableColumn.name, UTableColumn);
Vue.use(VueCropper) 
// Vue.use(MintUI)

Vue.use(dragdrop)

Vue.use(ElementUI, {
  size: 'mini',// medium / small / mini
}); // size用于改变组件的默认尺寸，zIndex 设置弹框的初始 z-index（默认值：2000）
Vue.config.productionTip = false;
const HOST = window.location.protocol + '//' + window.location.host
 
Vue.use(http, {
  baseURL: HOST + process.env.VUE_APP_BACKEND_URL_PROXY
})

Vue.component('AddressText',AddressText);//地址显示组件
Vue.component('Box',Box);//div box
Vue.component('Grid', Grid); //数据列表组件
Vue.component('ElButton', ElButton); //重写 按钮组件、支持记录按钮点击
Vue.component('ElPagination', Pagination);//重写 分页组件
//注释掉，重写的表格列拖拽排序导致性能问题
// Vue.component('ElTable', Table);//重写 表格组件
// Vue.component('ElTableColumn', TableColumn);//重写 表格组件
Vue.component('ElMenu', Menu);//重写 Menu组件
Vue.component('ElSubmenu', Submenu);//重写 Menu组件
Vue.component('ElMenuItem', MenuItem);//重写 Menu组件
Vue.component('ElMenuItemGroup', MenuItemGroup);//重写 Menu组件


Vue.component('GsArea',GsArea);//区划选择组件
Vue.component('ZbArea',ZbArea);//区划选择组件
Vue.component('GsSelector',GsSelector);//下拉选择组件
Vue.component('EnumSelector',EnumSelector);//枚举选择组件
Vue.component('GsIcon',GsIcon);//共生图标
Vue.component('UdaSelector',UdaSelector);//数据权限选择控件

Vue.component('MapChsPoint',MapChsPoint);//地图选点组件
Vue.component('ZbrlMapChsPoint',ZbrlMapChsPoint);//地图选点组件

Vue.component("HTable",HTable);//列表组件，用于拖拽和自定义显示列

Vue.prototype.$echarts = echarts;

Vue.prototype.$exportColFilter = ExportColFilter;//导出表头过滤

Vue.use(permission)
// Vue.config.errorHandler = function (err, vm, info) {
//   errorLog(vm.$store.state.user, vm.$route.path, err, info)
//   console.error(err)
// }
import Print from '@/plugins/print/print'
Vue.use(Print) // 注册打印插件

//全局混入

//是否开启 日志记录
Vue.prototype._LOGGGER=true




Vue.prototype.__ftpPath =  window.location.protocol + '//' + window.location.host + process.env.VUE_APP_FTP_PATH;


window.__GetReleaseInfo=function (){  
  console.info("release mode: " + process.env.VUE_APP_MODE) 
  console.info("release version: " + process.env.VUE_APP_VERSION)
  console.info("relase time: " + process.env.VUE_APP_RELEASE_TIME)
  console.info(window.location.protocol + "//" + window.location.host+process.env.VUE_APP_FTP_PATH)
  return true
}


