import Vue from 'vue'
//通用实体接口
export default {
    /**
     *通用实例列表查询
     * @param {Object} params 
     * params = {
                    start: this.grid_pageSize * (this.grid_pageNo - 1),
                    limit: this.grid_pageSize,
                    cls:'com.gsh56.tower.bs.Addr',
                    fields:["id","name","tel","linkman","shipper.name","organ.name"],
                    sort:'linkman',
                    q: {
                        "linkman__like":"王"
                    }
                }
     */
    pageEntity(params) {
        return Vue.http.post("api/SysApiService/pageEntity", {q:JSON.stringify(params)});
    },
    
    saasRequest(svc, method, params) {
       /* Making a POST request to the API endpoint with the specified `svc` and `method`, passing
       `params` as the request body. Additionally, it sets the `TenantDomain` header to the current
       hostname of the window. */
        return Vue.http.post("api/" + svc + "/" + method, params, {headers:{"TenantDomain": window.location.hostname}});
    },
    /**
     * 通用Map查询
     * @param {Object} params 
     */
    pageMap(params) {
        return Vue.http.post("api/SysApiService/pageMap", {q:JSON.stringify(params)});
    },
    /**
     * 
     * @param {*} cls   com.gsh56.tower.oms.Order
     * @param {*} cmd  ord/order.list4shipper
     * @param {*} paramMap 
     */
    findList(cls,cmd,paramMap){
        return Vue.http.post("api/SysApiService/findList", {cls:cls,cmd:cmd,paramMap:JSON.stringify(paramMap)});
    },
    /**
     * 自定义查询
     * @param {*} cls com.gsh56.tower.oms.Order
     * @param {*} cmd  ord/order.list4shipper
     * @param {*} qp   {q:{}}
     */
    pageMapByCmd(cls,cmd,qp){
        return Vue.http.post("api/SysApiService/findPage", {cls:cls,cmd:cmd,qp:JSON.stringify(qp)});
    },

    pageMapS56ByCmd(cls,cmd,qp){
        return Vue.http.post("api/Society56ApiService/findPage", {cls:cls,cmd:cmd,qp:JSON.stringify(qp)});
    },


    pageMapByCmdSaas(cls,cmd,qp){
        return Vue.http.post("api/SysApiService/findPage", {cls:cls,cmd:cmd,qp:JSON.stringify(qp)},{headers:{"TenantDomain": window.location.hostname}});
    },
    /**
     * 获取单个实体
     * @param {String} cls  class com.gsh56.tower.bs.Addr
     * @param {Number} id  主键 1
     * @param {Number} depth dto关联深度 1
     */

    get(cls,id,depth){
        return Vue.http.post("api/SysApiService/get", {cls:cls,id:id,depth:depth});
    },
    /**
     * 持久化实体
     * @param {String} cls class  com.gsh56.tower.bs.Addr
     * @param {Object} json  json
     *   const json ={
                //     name:"小王",
                //     linkman:'小王',
                //     mobile:'13909920093',
                //     shipper:{id:659},
                //     organ:{id:820}
                // }
     * 
     * 
     */
    save(cls,json){
        return Vue.http.post("api/SysApiService/saveWithJson", {cls:cls,json:JSON.stringify(json)});
    },
    /**
     * 修改方法 不覆盖未传入的json参数
     * @param {String} cls class  com.gsh56.tower.bs.Addr
     * @param {Object} json  json
     *   const json ={
                //     name:"小王",
                //     linkman:'小王',
                //     mobile:'13909920093',
                //     shipper:{id:659},
                //     organ:{id:820}
                // }
     * 
     * 
     */
    update(cls,json){
        return Vue.http.post("api/SysApiService/updateWithJson", {cls:cls,json:JSON.stringify(json)});
    },
    /**
     *  删除实体
     * @param {String} cls class com.gsh56.tower.bs.Addr
     * @param {Array} ids  id 数组 [1,2]
     */
    remove(cls,ids){
        return Vue.http.post("api/SysApiService/delete", {cls:cls, id:ids.join(',')});
    },

    /**
     *通用服务调用
     * @param {Object} svc 
     * @param {Object} method 
     * @param {Object} params 
     */
    request(svc,method,params) {
        return Vue.http.post("api/"+svc+"/"+method, params);
    },
    
    /**可视化客户列表 */
    getDatavCustList(params){
        return Vue.http.post('api/DatavService/getDatavCustList',{qp: JSON.stringify(params)})
    },
    

    /**添加可视化客户 */
    saveDatavCust(cust,fileIds){
        return Vue.http.post('api/DatavService/saveDatavCust',{cust:JSON.stringify(cust),fileids:fileIds})
    },
    

    /**获取可视化客户详情 */
    getDatavCust(custId){
        return Vue.http.post('api/DatavService/getDatavCust',{custId:custId})
    },

    /**车辆运费排名 */
    vhcSaleRank(){
        return Vue.http.post('api/DatavService/vhcSaleRank')
    },
    shipperSaleRank(){
        return Vue.http.post('api/DatavService/shipperSaleRank')
    },

    /**物流客户生态圈 */
    getLogisticsCustomersDatav(){
        return Vue.http.post('api/DatavService/getLogisticsCustomersDatav')
    },
    /**技术客户生态圈 */
    getTechnicalCustomersDatav(){
        return Vue.http.post('api/DatavService/getTechnicalCustomersDatav')
    },
    getTechnical(){
        return Vue.http.post('api/DatavService/getTechnical')
    }
}