// 基于准备好的dom，初始化echarts实例

export {renderHpHotVhcLine};
import datavApi from '@/project/datav/api/datavApi.js';
let echarts = require('echarts')
require('echarts/map/js/china')
require("echarts/lib/chart/scatter");
require("echarts/lib/chart/radar");
require("echarts/lib/chart/effectScatter");



var renderHpHotVhcLine = function (element,mapData) {

    var myChart = echarts.init(element);
    var option = {};
    var renderOption = function (data) {
        var series = []
        var geoCoordMap =  data.geoCoordMap == null ? {} : data.geoCoordMap;
        var translineData = data.translineData == null ? [] : data.translineData;
        //render  series
        var dlgeoData = [];
        var geoData = [];
        var mapName = "china";
        var mapFeatures = echarts.getMap(mapName).geoJson.features;
        mapFeatures.forEach(function (v) {
            var name = v.properties.name;
            // 地区经纬度
            geoData[name] = v.properties.cp;
        });
        if (geoData) {
            for (var item in geoData) {
                dlgeoData.push({name: item, value: geoData[item]})
            }
        }

        var convertData = function (data) {
            console.log(data);
            var res = [];
            for (var i = 0; i < data.length; i++) {
                var dataItem = data[i];
                var fromCoord = geoCoordMap[dataItem[0].name];
                var toCoord = geoCoordMap[dataItem[1].name];
                if (fromCoord && toCoord) {
                    res.push({
                        fromName: dataItem[0].name,
                        toName: dataItem[1].name,
                        coords: [fromCoord, toCoord],
                        value: dataItem[1].value
                    });
                }
            }
            return res;
        };


        var convertData2 = function (data) {
            console.log(data);
            var res = [];
            for (var i = 0; i < data.length; i++) {
                var dataItem = data[i];
                for(var j = 0; j< dataItem.length;j++){
                    var deliCity = {}
                    var arriCity = {}
                    var fromCoord = geoCoordMap[dataItem[j][0].name];
                    var toCoord = geoCoordMap[dataItem[j][1].name];
                    if (fromCoord ) {
                        deliCity.name =  dataItem[j][0].name
                        let value = [fromCoord[0],fromCoord[1],dataItem[j][1].value];
                        deliCity.value = value;
                        res.push(deliCity)
                    }
                    if (toCoord ) {
                        arriCity.name =  dataItem[j][1].name
                        let value = [toCoord[0],toCoord[1],dataItem[j][1].value];
                        arriCity.value = value;
                        res.push(arriCity)
                    }
                }
            }
            return res;
        };



        translineData.forEach(function (item, i) {
            //console.log(item,i);
            if (item.length > 0) {
                series.push(
                    {
                        name: item[0].name + ' i',
                        type: 'lines',
                        coordinateSystem: 'geo',
                        zlevel: 2,
                        symbol: ['none', 'none'],
                        symbolSize: 10,
                        effect: {
                            show: true,
                            period: 5,
                            symbol: 'circle',
                            trailLength: 0.1,
                            symbolSize: 4
                        },
                        lineStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0, color: '#58B3CC'
                                }, {
                                    offset: 1, color: '#F58158'
                                }], false),
                                width: 1,
                                opacity: 0.2,
                                curveness: 0.1
                            }
                        },
                        data: convertData(item)
                    }
                );
            }
        });

        series.push(
            {
                // name: 'pm2.5',
                type: 'scatter',
                coordinateSystem: 'geo',
                data: convertData2(translineData),
                symbolSize: function(val) {
                    return 1;
                },
                label: {
                    normal: {
                        formatter: '{b}',
                        position: 'top',
                        show: true,
                        fontSize:12,
                        color:"#fff"
                    },
                    emphasis: {
                        show: true
                    }
                },
                itemStyle: {
                    normal: {
                        color: '#ddb926',
                        position: 'right',
                        show: true
                    }
                }
            },
        );

        option = {
            animation: false,
            animationDuration: 500,
            animationEasing: 'linear',
            animationDurationUpdate: 500,
            animationEasingUpdate: 'linear',
            title: [{
                text: '',
                link: '',
                subtext: '',
                sublink: '',
                left: 'center',
                textStyle: {
                    color: "#fff",
                    fontSize: 12,
                    fontStyle : 'normal',
                    fontFamily : '微软雅黑 Light'
                }
            }],
            geo: {
                map: 'china',
                silent:true,
                center: [100.660782, 35.468074],
                zoom: 1,
                left:"17%",
                top:"15%",
                roam: false,
                label: {
                    normal: {
                        show: true,
                        color: "#fff",
                        fontSize:12,
                        emphasis: {
                            color: 'white',
                            show: false
                        }
                    },
                    emphasis: {
                        show: true
                    }
                },
     
                itemStyle: {
                    normal: {
                        areaColor: "#143A71",
                        borderColor: 'rgba(43,165,252,0.4)',
                        shadowColor: 'rgba(9,57,91,0)',
                        borderWidth : 0.2,
                        shadowBlur: 0,
                        shadowOffsetX: 0,
                        shadowOffsetY: 0
                    },
                    emphasis: {
                        areaColor: '#2a333d'
                    }
                }
            },
            tooltip: {
                trigger: 'item',
                formatter: function (params, ticket, callback) {
                    console.log(params);
                    if (params.seriesType == "effectScatter") {
                        return "线路：" + params.data.name + "" + params.data.value[2];
                    } else if (params.seriesType == "lines") {
                        return params.data.fromName + ">" + params.data.toName + "<br />" + params.data.value;
                    } else {
                        return params.name;
                    }
                }
            },
            series: series
        };
    };
    renderOption(mapData)
    myChart.setOption(option,true);
    

}





